import { aggregate as aggregate_, asRemoteAggregation } from '@/aggregation';

const parseDatasetsKeys = datasetsKeys =>
  _.isArray(datasetsKeys)
    ? new Set(datasetsKeys)
    : _.isString(datasetsKeys)
    ? new Set([datasetsKeys])
    : undefined;

export const aggregate = (
  datasets,
  { aggregation: remoteAggregation, datasetsKeys }
) => {
  datasetsKeys = parseDatasetsKeys(datasetsKeys);
  remoteAggregation = asRemoteAggregation(remoteAggregation);

  // filtering <datasets> keeping only the ones of keys included in
  // <datasetsKeys>
  if (!_.isUndefined(datasetsKeys) && datasetsKeys.size > 0)
    datasets = _.filter(datasets, ({ key }) => datasetsKeys.has(key));

  return aggregate_(_.flatMap(datasets, 'data'), remoteAggregation);
};
