import { tokens } from '@/helpers';

const buildAnnotation = annotation => {
  let { borderColor } = annotation;
  if (borderColor) borderColor = tokens.colors(borderColor);

  return Object.assign({}, annotation, { borderColor });
};

export default ({ annotations }) => ({
  annotation: {
    annotations: annotations.map(buildAnnotation),
  },
});
