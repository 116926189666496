<template>
  <DataGrid
    v-if="isDashboardManifest"
    :project-id="projectId"
    :manifest="manifest_"
    v-bind="$attrs"
    class="dashboard"
    v-on="$listeners"
  />
</template>

<script>
import { WITH_MANIFEST_PROPS } from './constants';
import { isDashboardManifest } from './helpers';

// components
import DataGrid from './DataGrid.vue';

export default {
  name: 'Dashboard',
  components: { DataGrid },
  props: {
    projectId: { type: String, required: true },

    ...WITH_MANIFEST_PROPS,
  },
  data() {
    const manifest = {
      ...this.manifest,

      title: { es: 'Analítica de proyecto', en: 'Project analytics' },
      icon: 'stats-bars',
    };

    return {
      manifest_: manifest,
      isDashboardManifest: isDashboardManifest(manifest),
    };
  },
};
</script>

<style lang="sass">
@import odd-ds/dist/lib/index.sass
</style>
