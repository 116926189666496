import BarChart from './BarChart/index.vue';
import BigNumberChart from './BigNumberChart/index.vue';
import HorizontalBarChart from './HorizontalBarChart/index.vue';
import LineChart from './LineChart/index.vue';
import { EventsTable } from '@odd-industries/odd-charting';

// stylesheets
import './style.sass';

export { default as BarChart } from './BarChart/index.vue';
export { default as BigNumberChart } from './BigNumberChart/index.vue';
export { default as HorizontalBarChart } from './HorizontalBarChart/index.vue';
export { default as LineChart } from './LineChart/index.vue';

export default {
  BarChart,
  BigNumberChart,
  EventsTable,
  HorizontalBarChart,
  LineChart,
};
