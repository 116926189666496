import { tokens } from '@/helpers';

export default () => ({
  crosshair: {
    line: {
      color: tokens.colors('secondary'),
      dashPattern: [4, 4],
    },

    snap: { enabled: true },
    sync: { enabled: false },
    zoom: { enabled: false },
  },
});
