import ChartJs from 'chart.js';
import NamedChart from './../NamedChart';
import Transformer from '@/charting/components/Transformer';
import buildChartJsOptions from './options';
import { exportChartAsCSV } from './serializers';

// global configurations
ChartJs.defaults.global.animation = false;

// plugins
// - annotation (https://github.com/chartjs/chartjs-plugin-annotation)
import AnnotationPlugin from 'chartjs-plugin-annotation';

// - crosshair (https://github.com/abelheinsbroek/chartjs-plugin-crosshair)
import CrosshairPlugin from 'chartjs-plugin-crosshair';

// components
import ChartJsEmpty from '@/charting/components/utils/ChartJsEmpty.vue';
import Tooltips from '@/charting/mixins/ChartJs/options/tooltips/Tooltips.vue';

const DEBOUNCE_WAIT = 100;
const LEGEND_DATASET_CLASS_NAME = 'legend__dataset';

export default {
  mixins: [NamedChart],
  components: { ChartJsEmpty, Tooltips },
  props: {
    legendWrapper: {
      type: HTMLDivElement,
      default: undefined,
    },
    fetchingComponent: {
      type: Boolean,
      default: false,
    },

    chartJsOptions: {
      type: Object,
      default: undefined,
    },
  },
  data() {
    return {
      labels: [],

      dp: null,

      chart: null,
      tooltips: null,

      debouncedUpdate: _.debounce(this.update, DEBOUNCE_WAIT),
      isWaitingUpdate: false,
    };
  },
  computed: {
    // START - building datasets -----------------------------------------------
    datasets_() {
      return this.buildDatasets(this.datasets);
    },
    // END - building datasets -------------------------------------------------

    // START - building ChartJs options ----------------------------------------
    options_() {
      // given <options>, we build edited options

      return _.merge(
        {},
        this.options, // outer,
        this.innerOptions // inner
      );
    },

    chartJsOptions_() {
      // given <options_>, we build edited ChartJs options

      const chartJsHandlers = Object.assign(
        {},
        this.onClick && { onClick: this.onClick },
        this.onResize && { onResize: this.onResize }
      );

      return _.merge(
        buildChartJsOptions(this.tooltips, this.options_),
        this.chartJsOptions, // outer
        this.innerChartJsOptions, // inner

        chartJsHandlers
      );
    },
    // END - building ChartJs options ------------------------------------------

    isEmpty() {
      return (
        !this.fetchingComponent &&
        _.every(this.datasets_, dataset => _.isEmpty(dataset.data))
      );
    },
  },
  watch: {
    labels() {
      if (!this.chart) return;

      this.chart.data.labels = this.labels;
      this.debouncedUpdate();

      this.setupLegend();
    },

    datasets_() {
      if (!this.chart) return;

      this.chart.data.datasets = this.datasets_;
      this.updateChartDatasetsMeta(this.chart);
      this.debouncedUpdate();

      this.setupLegend();
    },

    chartJsOptions_() {
      if (!this.chart) return;

      this.chart.options = this.chartJsOptions_;
      this.debouncedUpdate();
    },

    fetchingComponent(current, previous) {
      if (!current || previous) return;

      this.isWaitingUpdate = true;

      this.$a({
        targets: this.$el,

        opacity: [1, 0],
        scale: 1.025,

        duration: 250,
        easing: 'easeOutQuad',
      });
    },

    isWaitingUpdate(current, previous) {
      if (current || !previous) return;

      this.$a({
        targets: this.$el,

        opacity: [0, 1],
        scale: 1,

        duration: 250,
        easing: 'easeInQuad',
      });
    },
  },
  created() {
    this.transformer = new Transformer(this.$options.name, this.options_);
  },
  mounted() {
    const { canvas } = this.$refs;

    this.setup(canvas);
    this.setupTooltips();
  },
  methods: {
    update() {
      if (!this.chart) return;

      this.chart.update();

      this.isWaitingUpdate = false;
    },

    updateChartDatasetsMeta(chart) {
      const { initially_hidden: initiallyHidden } = this.options_;
      if (!initiallyHidden) return;

      chart.data.datasets.forEach((dataset, index) => {
        const meta = chart.getDatasetMeta(index);
        if (!_.isNull(meta.hidden)) return;

        const isHidden = initiallyHidden.includes(dataset.key);

        meta.hidden = isHidden;
      });
    },

    setup(canvas) {
      this.$emit('before-setup');

      const chart = new ChartJs(canvas, {
        plugins: [AnnotationPlugin, CrosshairPlugin],

        type: this.type,
        data: { labels: this.labels, datasets: this.datasets_ },
        options: this.chartJsOptions_,
      });
      this.updateChartDatasetsMeta(chart);

      this.chart = chart;

      this.$emit('after-setup', { exportAs: this.exportAs });
    },

    setupLegend() {
      this.$emit('before-legend-setup');

      const wrapper = this.legendWrapper;
      if (!wrapper) return;

      wrapper.innerHTML = this.chart.generateLegend();
      wrapper
        .querySelectorAll(`.${LEGEND_DATASET_CLASS_NAME}`)
        .forEach(legendDataset =>
          legendDataset.addEventListener('click', this.onLegendClick, false)
        );

      this.$emit('after-legend-setup');
    },

    setupTooltips() {
      this.tooltips = this.$refs.tooltips;
    },

    // to override
    buildDatasets: _.identity,

    exportAs(format) {
      switch (format) {
        case 'csv':
          return exportChartAsCSV(this.chart, this.name);

        default:
          return exportChartAsCSV(this.chart, this.name);
      }
    },

    onClick(e, elements) {
      if (_.isEmpty(elements)) return;
      const [element] = elements;

      this.$emit('click', element);
    },
    onLegendClick(e) {
      const HIDDEN_MODIFIER = 'hidden';

      e = e || window.event;
      let target = e.target || e.srcElement;
      while (!target.classList.contains(LEGEND_DATASET_CLASS_NAME))
        target = target.parentElement;

      const targetParent = target.parentElement;
      const index = Array.from(targetParent.children).indexOf(target);
      const datasetMeta = this.chart.getDatasetMeta(index);

      if (!datasetMeta.hidden) {
        datasetMeta.hidden = true;

        target.classList.add(
          `${LEGEND_DATASET_CLASS_NAME}--${HIDDEN_MODIFIER}`
        );
      } else {
        target.classList.remove(
          `${LEGEND_DATASET_CLASS_NAME}--${HIDDEN_MODIFIER}`
        );

        datasetMeta.hidden = null;
      }

      this.chart.update();
    },
  },
};
