<template>
  <div class="chart-js">
    <div class="horizontal-bar-chart">
      <canvas ref="canvas" />

      <Tooltips ref="tooltips" />
    </div>

    <ChartJsEmpty v-if="isEmpty" />
  </div>
</template>

<script>
import { ChartJs } from '@/charting/mixins';

export default {
  name: 'HorizontalBarChart',
  mixins: [ChartJs],
  data() {
    return {
      type: 'horizontalBar',
      innerOptions: { axesType: 'default' },
      innerChartJsOptions: {
        plugins: { crosshair: false },
      },
    };
  },
  methods: {
    // START - building datasets -----------------------------------------------
    buildDatasets(datasets) {
      if (!this.transformer) return;

      const { labels, datasets: datasets_ } = this.transformer.preprocess(
        datasets
      );

      // FIX
      this.labels = labels;

      return datasets_;
    },
    // END - building datasets -------------------------------------------------
  },
};
</script>

<style lang="sass">
@import odd-ds/dist/lib/index.sass
</style>
