<template>
  <div v-show="isSelected">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'DataTabsTabBody',
  props: {
    name: { type: String, required: true },
  },
  data() {
    return { isSelected: false };
  },
  created() {
    this.$on('kpi-fetched', kpi => {
      this.$parent.$parent.$parent.$emit('kpi-fetched', {
        name: this.name,
        kpi,
      });
    });
  },
};
</script>

<style lang="sass">
@import odd-ds/dist/lib/index.sass
</style>
