import { time } from '@/helpers';
import { isDefaultChart } from '@/charting/mixins/ChartJs/helpers';

const buildTooltipTitle = (tooltipModel, chart) => {
  // the first one as representative of all!
  const [dataPoint] = tooltipModel.dataPoints;
  const { index } = dataPoint;

  const { unit, displayFormats } = chart.options.scales.xAxes[0].time;
  const format = displayFormats[unit];

  if (isDefaultChart(chart)) {
    let title = chart.data.labels[index];
    if (moment.isMoment(title)) title = time.format.as(title, format);

    return title;
  }

  return time.format.as(
    chart.data.datasets[dataPoint.datasetIndex].data[index].x,
    format
  );
};

const buildTooltipDatasetByIndex = (tooltipModel, index) => {
  const color = tooltipModel.labelColors[index].backgroundColor;
  const [label, value] = tooltipModel.body[index].lines[0].split(': ');

  return { color, label, value };
};

const COMMON = Object.freeze({
  hover: { intersect: false },
});

const TOOLTIPS_COMMON = Object.freeze({ mode: 'index', intersect: false });

export default (instance, formatter) =>
  instance
    ? {
        ...COMMON,
        tooltips: {
          ...TOOLTIPS_COMMON,

          callbacks: {
            label(tooltipItem, data) {
              const label = data.datasets[tooltipItem.datasetIndex].label || '';
              const value = formatter(
                _.isNumber(tooltipItem.yLabel)
                  ? tooltipItem.yLabel
                  : tooltipItem.xLabel
              );

              return `${label}: ${value}`;
            },
          },

          enabled: false,
          custom(tooltipModel) {
            const { $data } = instance;
            const { opacity, caretX, caretY } = tooltipModel;

            // display
            $data.show = opacity > 0 && !(_.isNaN(caretX) || _.isNaN(caretY));
            if (!$data.show) return;
            $data.x = caretX;
            $data.y = caretY;
            $data.xAlign = tooltipModel.xAlign;

            // title & datasets
            $data.title = buildTooltipTitle(tooltipModel, this._chart);
            const datasets = [];
            for (let i = 0; i < tooltipModel.body.length; i++)
              datasets.push(buildTooltipDatasetByIndex(tooltipModel, i));
            $data.datasets = datasets;
          },
        },
      }
    : {
        ...COMMON,
        tooltips: {
          ...TOOLTIPS_COMMON,

          callbacks: {
            label(tooltipItem, data) {
              const label = data.datasets[tooltipItem.datasetIndex].label || '';
              const value = formatter(
                _.isNumber(tooltipItem.yLabel)
                  ? tooltipItem.yLabel
                  : tooltipItem.xLabel
              );

              return `${label}: ${value}`;
            },
          },
        },
      };
