import { DEFAULT_OPTIONS } from './constants';
import { tokens } from '@/helpers';

export const mapColorOption = ({ color }) => {
  color = tokens.colors(color) || tokens.colors(DEFAULT_OPTIONS.color);
  const options = { backgroundColor: color, borderColor: color };

  return options;
};

export const parseDataPointsDatasets = dataPointsDatasets => {
  let labels = []; // set to keep unique labels
  let datasets = []; // hash to index dataset

  // collecting <labels>
  _(dataPointsDatasets)
    .flatMap('data')
    .forEach(({ x }) => labels.push(x));

  // setting up <labels>
  labels.sort((a, b) => a.diff(b));
  labels = _.sortedUniqBy(labels, label => label.format());

  // collecting <datasets>
  dataPointsDatasets.forEach(dataset => {
    const data = labels.map(label => {
      const dataPoint = _.find(
        dataset.data,
        ({ x }) => x.format() === label.format()
      ) || { x: label };

      return dataPoint.y;
    });

    datasets.push({ ...dataset, data });
  });

  return { labels, datasets };
};
