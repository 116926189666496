import CSV from 'comma-separated-values';
import { downloadByAnchor, time } from '@/helpers';
import { isDefaultChart } from './helpers';

const downloadCSV = (data, header, filename) => {
  const csv = new CSV(data, { header });
  const encodedURI = encodeURI('data:text/csv;charset=utf-8,' + csv.encode());

  downloadByAnchor(encodedURI, `${filename}.csv`);
};

const exportDefaultChartAsCSV = (chart, filename) => {
  const x = {};
  const columns = [];
  const rows = chart.data.labels;

  chart.data.datasets.forEach(dataset => {
    columns.push(dataset.label);

    rows.forEach((row, index) => {
      const bucket = x[row] || (x[row] = []);

      let dataPoint = dataset.data[index];
      if (_.isPlainObject(dataPoint)) dataPoint = dataPoint.y;

      bucket.push(dataPoint);
    });
  });

  const data = _.map(x, (rowData, row) => [row, ...rowData]);
  const header = ['category', ...columns];

  downloadCSV(data, header, filename);
};

const exportScatterChartAsCSV = (chart, filename) => {
  const x = {};
  let columns = new Set();
  let rows = new Set();

  chart.data.datasets.forEach(dataset => {
    dataset.data.forEach(dataPoint => {
      const x_ = time.format.asDatetime(dataPoint.x);
      const bucket = x[x_] || (x[x_] = {});

      bucket[dataset.label] = dataPoint.y;

      rows.add(x_);
      columns.add(dataset.label);
    });
  });

  columns = Array.from(columns);
  columns.sort();
  rows = Array.from(rows);
  rows.sort();

  const data = rows.map(row => [
    row,
    ...columns.map(column => x[row][column] || 0),
  ]);
  const header = ['timestamp', ...columns];

  downloadCSV(data, header, filename);
};

export const exportChartAsCSV = (chart, filename) =>
  isDefaultChart(chart)
    ? exportDefaultChartAsCSV(chart, filename)
    : exportScatterChartAsCSV(chart, filename);
