<template>
  <DataContainer
    class="data-tabs"
    :project-id="projectId"
    :dimension-id="dimensionId"
    :manifest="manifest"
    @ready="setup"
  >
    <template v-slot:header>
      <div
        :style="{
          'grid-template-columns': `
            repeat(${tabs.length}, ${tabs.length > 1 ? 'max-content' : '1fr'})
          `,
        }"
        class="data-tabs__tabs"
      >
        <DataTabsTab
          v-for="tab in tabs"
          :key="tab.$props.name"
          :with-indicator="tabs.length > 1"
          :class="tabClassName(tab)"
          @click="onTabClick(tab)"
        >
          <BigNumberChart
            v-if="kpis[tab.$props.name]"
            v-bind="kpis[tab.$props.name]"
          />
        </DataTabsTab>

        <DataTabsTab v-if="tabs.length > 1" />
      </div>
    </template>

    <template v-slot:default>
      <div class="data-tabs__tab-body">
        <DataTabsTabBody
          v-for="value in areas"
          :key="`${dimensionId}-${value.key}`"
          ref="tabBody"
          :name="value.key"
        >
          <LeafManifestChart
            v-bind="payload(value)"
            @fetching="onTabFetching({ name: value.key, fetching: $event })"
          />

          <Progress v-if="tabsFetching[value.key]" variant="odd" with-overlay />
        </DataTabsTabBody>
      </div>
    </template>
  </DataContainer>
</template>

<script>
import { db } from '@/config/firebase';
import { LeafManifest } from '@odd-industries/odd-charting';
import { WithTime } from '@/mixins';
import mixin from './../mixin';
import { INITIALLY_SELECTED } from './constants';
import { functions } from '@/helpers';
import { isDataTabsTabBody } from './helpers';

// components
import DataContainer from './../DataContainer.vue';
import DataTabsTab from './DataTabsTab.vue';
import DataTabsTabBody from './DataTabsTabBody.vue';
import { BigNumberChart } from '@/charting/components';
import { LeafManifestChart } from '@odd-industries/odd-charting-components';

const METRICS_COLLECTION_REF = db.collection('Metrics');
const REGISTERS_COLLECTION_REF = db.collection('Registers');

const fetchRegisterSignedURISd = file => {
  const match = /(.+)\/(.+)\/(.+)\/(.+)\/(.+)\.jpg/.exec(file.filename);

  if (match) {
    const BUCKET = 'process-bucket-cmdic';

    const [, serviceId, year, month, day, timestamp] = match;
    const filename = `${serviceId}/${year}-${month}-${day}/THUMBNAIL/${timestamp}-480.jpg`;

    return functions.fetchFileSignedURI({
      bucket: BUCKET,
      filename,
      outputFilename: undefined,
    });
  }

  return '';
};

const fetchRegisterSignedURIHd = file => functions.fetchFileSignedURI(file);

export default {
  name: 'DataTabs',
  components: {
    BigNumberChart,
    DataContainer,
    DataTabsTab,
    DataTabsTabBody,
    LeafManifestChart,
  },
  mixins: [WithTime, mixin],
  data() {
    return {
      tabs: [],
      kpis: {},

      withHeader: false,
      withFooter: false,

      tabsFetching: {},
    };
  },
  computed: {
    areas() {
      const { areas } = this.manifest;

      return _.map(areas, (area, key) => {
        const m = new LeafManifest(area);

        m.key = area.key || String(key);

        return m;
      });
    },

    bind() {
      const bind = { projectId: this.projectId, dimensionId: this.dimensionId };

      return Object.assign(bind, this.$attrs);
    },
  },
  created() {
    this.$on('kpi-fetched', ({ kpi, name }) => {
      this.$set(this.kpis, name, kpi);
    });
  },
  methods: {
    parseTabs() {
      return _(this.$refs.tabBody)
        .map('$vnode')
        .filter(isDataTabsTabBody)
        .map(vnode => vnode.componentInstance)
        .value();
    },

    setup() {
      this.tabs = this.parseTabs();

      this.withHeader = Boolean(this.$slots.header);
      this.withFooter = Boolean(this.$slots.footer);

      this.selectTab(this.tabs[INITIALLY_SELECTED]);
    },

    selectTab(tabToSelect) {
      this.tabs.forEach(tab => {
        tab.$data.isSelected = tab.$props.name === tabToSelect.$props.name;
      });
    },

    onTabClick(clickedTab) {
      this.selectTab(clickedTab);
    },
    onTabFetching({ name, fetching }) {
      this.$set(this.tabsFetching, name, fetching);
    },

    tabClassName(tab) {
      const className = 'data-tabs__tab';

      return [className, { [`${className}--active`]: tab.$data.isSelected }];
    },

    payload(manifest) {
      const fetchRegisterURIById = {
        sd: async registerId => {
          const uri = await fetchRegisterSignedURISd(
            (await REGISTERS_COLLECTION_REF.doc(registerId).get()).data()
          );

          return `${registerId}|${uri}`;
        },
        hd: async registerId => {
          const uri = await fetchRegisterSignedURIHd(
            (await REGISTERS_COLLECTION_REF.doc(registerId).get()).data()
          );

          return `${registerId}|${uri}`;
        },
      };

      return {
        name: 'foo',

        metricsRef: METRICS_COLLECTION_REF,
        manifest,

        dateRange: this.dateRange,
        granularity: 2,
        limitDateRange: this.limitDateRange,

        eventMapper: {
          registersIds: event =>
            Promise.all(_.map(event.registersIds, fetchRegisterURIById.sd)),
          servicesIds: event =>
            String(
              _.map(
                event.servicesIds,
                (() => {
                  const REG_EXP = /(Nave)(\d+)/g;

                  return serviceId => {
                    const match = REG_EXP.exec(serviceId);
                    REG_EXP.lastIndex = 0;

                    if (match) {
                      const [, nave, n] = match;

                      return _.capitalize(`${nave} ${n}`);
                    }

                    return '';
                  };
                })()
              )
            ),
          type: event => this.$t(`c.events-table.types.${event.type}`),
        },

        fetchRegisterURIById,
      };
    },
  },
};
</script>

<style lang="sass">
@import odd-ds/dist/lib/index.sass

// base
$data-grid-border-width: 1px

.data-tabs
  overflow-x: auto

// - tabs
.data-tabs__tabs
  display: grid
  grid-auto-flow: column
  grid-template-rows: 1fr

  overflow-x: auto

// - tab's DataGrid
.data-tabs .data-grid__body
  gap: 0

  & > *
    border-bottom-style: solid
    border-bottom-width: $data-grid-border-width

  & > *:last-child
    border-bottom: none

// color
// - tabs
.data-tabs__tabs
  background-color: var(--c-gray-0)

// - tab's DataGrid
.data-tabs .data-grid__body
  & > *
    border-bottom-color: var(--c-gray-1)
</style>
