import { GRANULARITY_TO_UNIT_MAPPING } from '@/granularity';
import { chunkString, formatters, time, tokens } from '@/helpers';

const TIME_AXES_DISPLAY_FORMATS = Object.freeze({
  minute: 'DD/MM hh:mm A',
  hour: 'DD/MM hh A',
  day: 'DD MMM',
  week: 'DD MMM',
  month: 'MM/YYYY',
  quarter: '[Q]Q - YYYY',
  year: 'YYYY',
});

// base ---------------------------------------------------------------------
const GRID_LINES = Object.freeze({
  color: tokens.colors('gray-0'),
  drawBorder: false,
  zeroLineColor: tokens.colors('gray-0'),
});

const TICKS = Object.freeze({
  beginAtZero: true,
  fontColor: tokens.colors('gray-2'),
  fontFamily: tokens.fonts.families('primary'),
  maxRotation: 0,
  minRotation: 0,
  padding: 8,
});

const X_AXIS_TICKS = (callback, maxTicksLimit = 4) =>
  Object.assign({ ...TICKS, maxTicksLimit }, callback && { callback });

const Y_AXIS_TICKS = (callback, maxTicksLimit = 6) =>
  Object.assign({ ...TICKS, maxTicksLimit }, callback && { callback });

const buildXAxisFormatter = ({ unit, granularity }) => {
  unit = unit || GRANULARITY_TO_UNIT_MAPPING[granularity];
  const format = TIME_AXES_DISPLAY_FORMATS[unit];

  return value => {
    if (moment.isMoment(value)) {
      return time.format.as(value, format);
    } else return value;
  };
};

const buildYAxisFormatter = ({
  yAxisPrecision,
  isWorkDay,
  isWorkDaySE,
}) => value => {
  if (_.isNumber(value)) {
    if (isWorkDay) return formatters('duration')(value, yAxisPrecision);
    if (isWorkDaySE) return formatters('hourOfDay')(value, yAxisPrecision);

    return formatters('abbreviate')(value);
  }

  if (_.isString(value)) return chunkString(value, 20);

  return value;
};

const buildBase = ({
  xAxisFormatter,
  yAxisFormatter,

  xAxisStacked,
  yAxisStacked,
  stacked, // yAxisStacked alias
}) => {
  return {
    scales: {
      xAxes: [
        {
          gridLines: GRID_LINES,
          ticks: X_AXIS_TICKS(xAxisFormatter, Infinity),

          stacked: xAxisStacked,
        },
      ],
      yAxes: [
        {
          gridLines: GRID_LINES,
          ticks: Y_AXIS_TICKS(yAxisFormatter),

          stacked: yAxisStacked || stacked,
        },
      ],
    },
  };
};

// default ---------------------------------------------------------------------
const withDefaultAxes = payload => {
  const { granularity, unit, yAxisPrecision, isWorkDay, isWorkDaySE } = payload;

  const xAxisFormatter = buildXAxisFormatter({ unit, granularity });
  const yAxisFormatter = buildYAxisFormatter({
    yAxisPrecision,
    isWorkDay,
    isWorkDaySE,
  });

  const time = {
    unit: unit || GRANULARITY_TO_UNIT_MAPPING[granularity],
    displayFormats: TIME_AXES_DISPLAY_FORMATS,
    isoWeekday: true,
  };

  return _.merge(buildBase(payload), {
    scales: {
      xAxes: [{ time, ticks: X_AXIS_TICKS(xAxisFormatter) }],
      yAxes: [{ ticks: Y_AXIS_TICKS(yAxisFormatter) }],
    },
  });
};

// time ------------------------------------------------------------------------

const withTimeAxes = payload => {
  const { granularity, unit, yAxisPrecision, isWorkDay } = payload;

  const time = {
    unit: unit || GRANULARITY_TO_UNIT_MAPPING[granularity],
    displayFormats: TIME_AXES_DISPLAY_FORMATS,
    isoWeekday: true,
  };

  // xAxisFormatter will be forced by ChartJs
  const yAxisFormatter = buildYAxisFormatter({ yAxisPrecision, isWorkDay });

  return _.merge(buildBase(payload), {
    scales: {
      xAxes: [{ type: 'time', time, ticks: X_AXIS_TICKS() }],
      yAxes: [{ ticks: Y_AXIS_TICKS(yAxisFormatter) }],
    },
  });
};

// without ---------------------------------------------------------------------
const withoutAxes = () => ({
  scales: {
    xAxes: [
      {
        type: 'linear',

        gridLines: { display: false },
        ticks: { display: false },
      },
    ],
    yAxes: [
      {
        type: 'linear',

        gridLines: { display: false },
        ticks: { display: false },
      },
    ],
  },
});

export default (axesType, payload) => {
  switch (axesType) {
    case 'default':
      return withDefaultAxes(payload);
    case 'time':
      return withTimeAxes(payload);

    default:
      return withoutAxes(payload);
  }
};
