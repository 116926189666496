export const isDefaultChart = chart =>
  chart.data.labels && chart.data.labels.length > 0;

export const isScatterChart = chart =>
  _.some(
    chart.data.datasets,
    ({ label, data }) =>
      Boolean(label) &&
      _.some(data, dataPoint => Boolean(dataPoint.x) && Boolean(dataPoint.y))
  );

export const isShallowChart = chart =>
  isDefaultChart(chart) &&
  !_.some(chart.data.datasets, ({ label }) => Boolean(label));
