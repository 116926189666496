import DatasetsPreprocessor from './DatasetsPreprocessor';
import BarChartDatasetsPreprocessor from './BarChart/DatasetsPreprocessor';
import HorizontalBarChartDatasetsPreprocessor from './HorizontalBarChart/DatasetsPreprocessor';
import LineChartDatasetsPreprocessor from './LineChart/DatasetsPreprocessor';

import { parseI18nText } from '@/helpers';

const getDatasetKey = dataset => parseI18nText(dataset.label);

const innerExtract = datasets => {
  const data = {};
  let datasetsKeys = new Set();

  let areTimeDataPoints =
    _.some(
      datasets,
      dataset => dataset.data.length > 0 && moment.isMoment(dataset.data[0].x)
    ) || false;

  datasets.forEach(dataset => {
    const datasetKey = getDatasetKey(dataset);

    datasetsKeys.add(datasetKey);

    dataset.data.forEach(dataPoint => {
      const bucketKey = areTimeDataPoints ? dataPoint.x.format() : dataPoint.x;
      const bucket = data[bucketKey] || (data[bucketKey] = {});

      bucket[datasetKey] = dataPoint.y;
    });
  });
  datasetsKeys = Array.from(datasetsKeys);

  const labels = [];
  const datasetsByKeys = _(datasets)
    .keyBy(getDatasetKey)
    .mapValues(dataset => ({ ...dataset, data: [] }))
    .value();

  _.forEach(
    _.sortBy(_.entries(data), ([bucketKey]) => bucketKey),
    ([bucketKey, bucket]) => {
      bucketKey = areTimeDataPoints ? moment(bucketKey) : bucketKey;
      labels.push(bucketKey);

      _.forEach(datasetsKeys, datasetKey => {
        const value = bucket[datasetKey] || 0;

        datasetsByKeys[datasetKey].data.push(value);
      });
    }
  );

  return { labels, datasets: _.values(datasetsByKeys) };
};

class GeneralDatasetsPreprocessor extends DatasetsPreprocessor {
  constructor(type, options) {
    super(options);

    this.type = type;

    this.labelPreprocessor = this.getLabelPreprocessor();
    this.dataPreprocessor = this.getDataPreprocessor();

    this.typeDatasetsPreprocessor = this.getTypeDatasetsPreprocessor(this.type);

    // binding
    this.map = this.map.bind(this);
    this.reduce = this.reduce.bind(this);
  }

  getTypeDatasetsPreprocessor(type) {
    switch (type) {
      case 'BarChart':
        return new BarChartDatasetsPreprocessor(this.options);
      case 'HorizontalBarChart':
        return new HorizontalBarChartDatasetsPreprocessor(this.options);
      case 'LineChart':
        return new LineChartDatasetsPreprocessor(this.options);

      default:
        return new DatasetsPreprocessor(this.options);
    }
  }

  isScatterChartData(data) {
    // checks if data is of the form { x, y } or not.

    const dp = data[0]; // the first one as representative of all!

    return _.has(dp, 'x') || _.has(dp, 'y');
  }

  getLabelPreprocessor() {
    return parseI18nText;
  }

  getDataPreprocessor() {
    return _.identity;
  }

  map(dataset, outerMapPayload) {
    const outerMap = this.typeDatasetsPreprocessor.map;
    const innerMap = dataset => ({
      ...dataset,

      label: this.labelPreprocessor(dataset.label),
      data: this.dataPreprocessor(dataset.data),

      stack: dataset.stack || 0,
    });

    return outerMap(innerMap(dataset), outerMapPayload);
  }

  reduce(labels, datasets) {
    const LABEL_KEY = 'stackLabel';

    // grouping and merging <datasets> by their keys
    const datasetsGroups = _.groupBy(datasets, getDatasetKey);
    datasets = _.map(datasetsGroups, datasetsGroup => {
      // the first one as representative of all!
      const bucket = { ...datasetsGroup[0], data: [] };

      return _(datasetsGroup)
        .sortBy(LABEL_KEY)
        .reduce((bucket, { data }) => {
          bucket.data.push(...data);

          return bucket;
        }, bucket);
    });

    return { labels, datasets };
  }

  preprocess(datasets, canvas) {
    const outerMapPayload = { canvas };

    const { extract: outerExtract } = this.typeDatasetsPreprocessor;
    const { labels, datasets: datasets_ } = (outerExtract || innerExtract)(
      datasets
    );

    return this.reduce(
      labels,
      datasets_.map(dataset => this.map(dataset, outerMapPayload))
    );
  }
}

export default GeneralDatasetsPreprocessor;
