<template>
  <div class="data-grid">
    <div v-if="manifest.title" class="data-grid__header">
      <icon v-if="manifest.icon" class="mr-2">{{ manifest.icon }}</icon>
      <DataTitle :manifest="manifest" semibold />
    </div>

    <div :style="bodyStyle" class="data-grid__body">
      <DataNode
        v-for="(value, key) in manifest.areas"
        :key="`${dimensionId}-${key}`"
        :manifest="value"
        v-bind="bind"
        :style="{ 'grid-area': key }"
        v-on="$listeners"
      />
    </div>
  </div>
</template>

<script>
import { WithTime, WithViewport } from '@/mixins';
import mixin from './mixin';
import { AREAS_TEMPLATES_KEY } from './constants';
import { tokens } from '@/helpers';

// components
import DataNode from './DataNode.vue';
import DataTitle from './DataTitle.vue';

export default {
  name: 'DataGrid',
  components: { DataNode, DataTitle },
  mixins: [WithTime, WithViewport, mixin],
  computed: {
    templates() {
      /**
       * writing defined templates and undefined templates using the closest
       * defined one (mobile-first). Our algorithm assumes the "xs" template is
       * defined!
       */

      let templates = this.manifest[AREAS_TEMPLATES_KEY];
      if (!templates) return;
      if (_.isString(templates)) templates = { xs: templates };

      let lastNotNullTemplate;

      return _(tokens.allBreakpoints())
        .mapValues((value, key) => {
          const template = templates[key] || null;

          if (_.isNull(template)) return lastNotNullTemplate;
          else {
            lastNotNullTemplate = template;

            return template;
          }
        })
        .value();
    },

    bind() {
      const bind = { projectId: this.projectId, dimensionId: this.dimensionId };

      return Object.assign(bind, this.$attrs);
    },

    bodyStyle() {
      const template = this.templates && this.templates[this.mq.current];

      return Object.assign({}, template && { 'grid-template-areas': template });
    },
  },
};
</script>

<style lang="sass">
@import odd-ds/dist/lib/index.sass

// base
$gap: $spacing-4

.data-grid__header
  +d-flex-r(center)
  +v-padding($spacing-3)

.data-grid__body
  display: grid
  gap: $gap

+media-down(md)
  .data-grid__header
    +h-padding($spacing-2)

    & > .e-text
      font-size: $f-size-sm

+media-up(md)
  .data-grid__header
    margin-bottom: $spacing-3

    border-radius: 4px // FIX
    box-shadow: $elevation-1

    +h-padding($spacing-4)

    & > .e-text
      font-size: $f-size-md

// color
+media-up(md)
  .data-grid__header
    background-color: var(--c-background)

    & > .e-icon, & > .e-text
      --color: var(--c-secondary)

+media-down(md)
  .data-grid__header
    & > .e-icon, & > .e-text
      --color: var(--c-gray-2)
</style>
