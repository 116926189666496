var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"v-projects-show-summary"},[(_vm.timeReady)?_c('Dashboard',{key:_vm.project.id,attrs:{"project-id":_vm.project.id,"dimension-id":_vm.dimensionId,"manifest":_vm.project.manifest.dashboard}}):_vm._e(),(_vm.projectReady)?[(_vm.mq['sm-'])?[_c('DateRangePickerMobile',{key:"projects-show-summary",attrs:{"dimension-id":_vm.dimensionId,"min-date":_vm.project.first.timestamp
            ? _vm.$time.moment(_vm.project.first.timestamp).startOf('day')
            : undefined,"max-date":_vm.project.last.timestamp
            ? _vm.$time.moment(_vm.project.last.timestamp).endOf('day')
            : undefined,"default-preset":1}})]:[_c('portal',{attrs:{"to":"header","slim":""}},[_c('DateRangePickerDesktop',{key:"projects-show-summary",attrs:{"dimension-id":_vm.dimensionId,"min-date":_vm.project.first.timestamp
              ? _vm.$time.moment(_vm.project.first.timestamp).startOf('day')
              : undefined,"max-date":_vm.project.last.timestamp
              ? _vm.$time.moment(_vm.project.last.timestamp).endOf('day')
              : undefined,"default-preset":1}})],1)]]:_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }