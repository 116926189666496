import './style.sass';
import { parseI18nText } from '@/helpers';
import { isShallowChart } from '@/charting/mixins/ChartJs/helpers';

const buildLegendSquare = color => `
  <div style="background-color: ${color}; border-color: ${color}" class="legend__square mr-2"></div>
`;

const buildLegendLabel = label => `
  <div style="--color: var(--c-gray-2)" class="legend__label e-text f-size-xs t-transform-capitalize">
    ${parseI18nText(label)}
  </div>
`;

const buildLegendDataset = ({ label, color, meta }) => {
  let className = 'legend__dataset';
  if (meta.hidden) className += ' legend__dataset--hidden';

  return `
    <div class="${className} d-flex-r d-flex-a-c">
      ${buildLegendSquare(color)} ${buildLegendLabel(label)}
    </div>
  `;
};

const buildShallowDatasetLegend = chart => {
  const COLOR_KEY = 'backgroundColor';
  let body = '';

  if (chart.data.datasets.length > 0) {
    const datasets = _.zip(
      chart.data.labels,
      chart.data.datasets[0][COLOR_KEY]
    );

    datasets.forEach(
      ([label, color], index) =>
        (body += buildLegendDataset({
          label,
          color,
          meta: chart.getDatasetMeta(index),
        }))
    );
  }

  return `<div class="legend d-flex-r h-spacing-r-3">${body}</div>`;
};

const buildDefaultDatasetLegend = chart => {
  const COLOR_KEY = 'borderColor';
  let body = '';

  const { datasets } = chart.data;
  datasets.forEach(
    ({ label, [COLOR_KEY]: color }, index) =>
      (body += buildLegendDataset({
        label,
        color,
        meta: chart.getDatasetMeta(index),
      }))
  );

  return `<div class="legend d-flex-r h-spacing-r-3">${body}</div>`;
};

const buildLegend = chart => {
  return isShallowChart(chart)
    ? buildShallowDatasetLegend(chart)
    : buildDefaultDatasetLegend(chart);
};

export default {
  legend: { display: false },

  legendCallback(chart) {
    return buildLegend(chart);
  },
};
