import DatasetsPreprocessor from './../DatasetsPreprocessor';
import { buildDatasetOptions } from './../DatasetsPreprocessor';

import { DEFAULT_OPTIONS } from './constants';
import { mapColorOption, mapBackgroundOption } from './helpers';

class LineChartDatasetsPreprocessor extends DatasetsPreprocessor {
  defaultOptions = DEFAULT_OPTIONS;

  constructor(options) {
    super(options);

    // binding
    this.map = this.map.bind(this);
  }

  map(dataset, { canvas }) {
    const datasetOptions = buildDatasetOptions(
      dataset,
      this.options,
      this.defaultOptions
    );

    return {
      ...dataset,

      ...mapColorOption(datasetOptions, dataset),
      ...mapBackgroundOption(datasetOptions, canvas),

      // styling
      borderWidth: 2,
      lineTension: 0,
    };
  }
}

export default LineChartDatasetsPreprocessor;
