<template>
  <div class="chart-js">
    <div class="line-chart">
      <canvas ref="canvas" />

      <Tooltips ref="tooltips" />
    </div>

    <ChartJsEmpty v-if="isEmpty" />
  </div>
</template>

<script>
import { ChartJs } from '@/charting/mixins';
import { DEBOUNCE_WAIT } from './constants';
import { rebuildBackgroundGradient } from './helpers';

export default {
  name: 'LineChart',
  mixins: [ChartJs],
  data() {
    return {
      type: 'line',
      innerOptions: { axesType: 'default' },

      rebuildBackgroundGradients: _.debounce(
        this.rebuildBackgroundGradients_,
        DEBOUNCE_WAIT
      ),
    };
  },
  methods: {
    // START - building datasets -----------------------------------------------
    buildDatasets(datasets) {
      if (!this.transformer) return;

      const { canvas } = this.$refs;
      const { labels, datasets: datasets_ } = this.transformer.preprocess(
        datasets,
        canvas
      );

      // FIX
      this.labels = labels;

      return datasets_;
    },

    rebuildBackgroundGradients_() {
      const { canvas } = this.$refs;

      _(this.datasets_)
        .filter(dataset => dataset.backgroundColor instanceof CanvasGradient)
        .forEach(dataset => rebuildBackgroundGradient(dataset, canvas));

      this.chart.update();
    },
    // END - building datasets -------------------------------------------------

    onResize() {
      this.rebuildBackgroundGradients();
    },
  },
};
</script>

<style lang="sass">
@import odd-ds/dist/lib/index.sass
</style>
