<template>
  <div class="data-tabs__tab" v-on="$listeners">
    <div class="d-flex-r d-flex-center"><slot /></div>

    <div v-if="withIndicator" class="data-tabs__tab-indicator" />
  </div>
</template>

<script>
export default {
  name: 'DataTabsTab',
  props: {
    withIndicator: { type: Boolean, default: true },
  },
};
</script>

<style lang="sass">
@import odd-ds/dist/lib/index.sass

// FIX
=on-active
  &--active
    @content

=on-inactive
  &:not(&--active)
    @content

// base
$body-selector: 'div:first-child'

$min-width: 96px
$min-height: 48px

$border-width: 1px

$transition-duration: $duration-quickly
$transition-timing-function: linear

$indicator-height: 2px

.data-tabs__tab
  position: relative

  +button
  +d-flex-c

// - body
.data-tabs__tab > #{$body-selector}
  flex-grow: 1

  min-width: $min-width
  min-height: $min-height
  padding: $spacing-2 $spacing-4

  border-bottom-style: solid
  border-bottom-width: $border-width
  border-left-style: solid
  border-left-width: $border-width

  transition: background-color $transition-duration $transition-timing-function

.data-tabs__tab:first-child > #{$body-selector}
  border-left: none

// - indicator
.data-tabs__tab-indicator
  content: ''

  flex-shrink: 0

  width: 100%
  height: $indicator-height

  opacity: 0

  transform: translateY(-$border-width)

  transition-duration: $transition-duration
  transition-property: opacity
  transition-timing-function: $transition-timing-function

.data-tabs__tab
  +on-active
    .data-tabs__tab-indicator
      opacity: 1

// color
// - body
.data-tabs__tab > #{$body-selector}
  border-bottom-color: var(--c-gray-1)
  border-left-color: var(--c-gray-1)

.data-tabs__tab
  +on-active
    & > #{$body-selector}
      background-color: var(--c-background)

// - indicator
.data-tabs__tab-indicator
  background-color: var(--c-primary)

// - tab's BigNumberChart
.data-tabs__tab
  +on-inactive
    .big-number-chart
      & > .e-icon,
      .big-number-chart__body > .e-text,
      .big-number-chart__body > div:nth-child(2) > .e-text
        --color: var(--c-gray-2)
</style>
