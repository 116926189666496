import { DEFAULT_OPTIONS } from './constants';
import { getPixelsValue, tokens } from '@/helpers';

export const buildBackgroundGradient = (color, canvas) => {
  const from = { position: [0, 0], color: tokens.colors(color) };
  const to = {
    position: [0, getPixelsValue(canvas.style.height)],
    color: tokens.colors('transparent'),
  };

  color = canvas
    .getContext('2d')
    .createLinearGradient(...from.position, ...to.position);
  color.addColorStop(0, from.color);
  color.addColorStop(1, to.color);

  return color;
};

export const rebuildBackgroundGradient = (dataset, canvas) => {
  // uses the backed up color to build the background again

  dataset.backgroundColor = buildBackgroundGradient(
    dataset.backgroundColor_,
    canvas
  );
};

export const mapColorOption = ({ color }, dataset) => {
  const HIGHLIGHTED_POINT_RADIUS = 4;

  color = tokens.colors(color) || tokens.colors(DEFAULT_OPTIONS.color);
  const options = {
    // - border
    borderColor: color,
    // - border hover
    hoverBorderColor: color,

    // - point
    pointBackgroundColor: color,
    pointBorderColor: 'white',
    pointBorderWidth: 0,
    pointRadius: 0,
    // - point hover
    pointHoverBackgroundColor: color,
    pointHoverBorderColor: 'white',
    pointHoverBorderWidth: 2,
    pointHoverRadius: 6,
  };

  const max = _.max(dataset.data);
  let maxFound = false;

  options.pointRadius = dataset.data.map(dataPoint => {
    if (!maxFound && dataPoint === max) {
      maxFound = true;

      return HIGHLIGHTED_POINT_RADIUS;
    } else return options.pointRadius;
  });

  return options;
};

export const mapBackgroundOption = ({ color, background }, canvas) => {
  const isDefault = _.isEqual(background, DEFAULT_OPTIONS.background);
  if (isDefault) return { backgroundColor: DEFAULT_OPTIONS.background.color };

  let { color: backgroundColor, gradient } = background;
  const isDefaultBackgroundColor =
    backgroundColor === DEFAULT_OPTIONS.background.color;
  backgroundColor =
    tokens.colors(isDefaultBackgroundColor ? color : backgroundColor) ||
    tokens.colors(DEFAULT_OPTIONS.background.color);
  const options = { backgroundColor };

  if (gradient) {
    // a color back up in case it needs a rebuild
    options.backgroundColor_ = options.backgroundColor;
    options.backgroundColor = buildBackgroundGradient(
      options.backgroundColor,
      canvas
    );
  }

  return options;
};
