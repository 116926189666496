<template>
  <div :style="style" class="tooltips">
    <div v-if="title" class="tooltips__title pv-2 pr-2">
      <t xs>{{ title }}</t>
    </div>

    <div class="tooltips__grid">
      <template v-for="{ key, color, label, value } in datasets">
        <div :key="`${key || label}-label`" class="tooltips__label">
          <div :style="{ backgroundColor: color }" class="tooltips__color" />

          <t color="gray-2" xs capitalize no-wrap>{{ label }}</t>
        </div>

        <div :key="`${key || label}-value`" class="tooltips__value">
          <t sm semibold no-wrap>{{ value }}</t>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Tooltips',
  data() {
    return {
      title: '',
      datasets: [],

      show: false,
      x: 0,
      y: 0,
    };
  },
  computed: {
    style() {
      const BOX_SHADOW_PADDING = 4;
      const TX = 24;
      const TX_PADDING = 16;

      let { show, x, y, xAlign } = this;

      if (this.$el) {
        const el = this.$el;
        const parent = el.parentElement;

        const rect = el.getBoundingClientRect();
        const parentRect = parent.getBoundingClientRect();

        const w = rect.width + BOX_SHADOW_PADDING;
        const h = rect.height + BOX_SHADOW_PADDING;
        const { width: W, height: H } = parentRect;

        const overflow = {
          top: Math.min(y - h / 2, 0),
          right: Math.max(x + w - W, 0),
          bottom: Math.max(y + h / 2 - H, 0),
          left: Math.min(x - w, 0),
        };

        xAlign = x < W / 2 ? 'right' : 'left';
        const xOverflow = xAlign === 'right' ? overflow.right : overflow.left;
        const yOverflow = overflow.bottom > 0 ? overflow.bottom : overflow.top;

        x -= xOverflow;
        y -= yOverflow;
      }

      return {
        '--x': `${x}px`,
        '--y': `${y}px`,

        opacity: Number(show),
        transform: `translate(${
          xAlign === 'right'
            ? `${TX + TX_PADDING}px`
            : `calc(-100% + ${TX - TX_PADDING}px)`
        }, -50%)`,
      };
    },
  },
};
</script>

<style lang="sass">
@import odd-ds/dist/lib/index.sass
@import @/assets/stylesheets/lib/index.sass

// base
$color-width: .5rem
$dataset-height: 32px

$label-max-width: 128px

.tooltips
  position: absolute
  top: var(--y)
  left: var(--x)

  border-radius: $b-radius-2
  box-shadow: $elevation-2

  transition: opacity $duration-quickly

  pointer-events: none

.tooltips__title,
.tooltips__label,
.tooltips__value
  border-bottom-style: solid
  border-bottom-width: 1px

.tooltips__label:nth-last-child(2),
.tooltips__value:nth-last-child(1)
  border-bottom: none

.tooltips__title
  padding-left: $color-width + $spacing-2

.tooltips__grid
  display: grid
  grid-template-columns: 1fr auto
  grid-auto-rows: $dataset-height
  align-items: center

.tooltips__label,
.tooltips__value
  height: 100%

  +d-flex-r(center)

.tooltips__label
  position: relative

  max-width: $label-max-width

  & > .e-text
    margin-left: $color-width + $spacing-2

    +text-overflow(true)

.tooltips__color
  width: $color-width

  +p-absolute($z-index-base)
  +shell-left(0)

.tooltips__value
  padding-right: $spacing-2
  padding-left: $spacing-3

// color
.tooltips
  background-color: var(--c-background)

.tooltips__title,
.tooltips__label,
.tooltips__value
  border-bottom-color: var(--c-gray-1)
</style>
