<template>
  <component :is="component" v-if="component" v-bind="bind" v-on="$listeners" />
</template>

<script>
import { WITH_MANIFEST_PROPS } from './constants';
import { isGridManifest, isSingleManifest, isTabsManifest } from './helpers';

// components
import DataGrid from './DataGrid.vue';
import DataSingle from './DataSingle.vue';
import DataTabs from './DataTabs/index.vue';

export default {
  name: 'DataNode',
  props: {
    projectId: { type: String, required: true },

    ...WITH_MANIFEST_PROPS,
  },
  computed: {
    manifestType() {
      if (isGridManifest(this.manifest)) return 'grid';
      else if (isSingleManifest(this.manifest)) return 'single';
      else if (isTabsManifest(this.manifest)) return 'tabs';

      return '';
    },

    component() {
      switch (this.manifestType) {
        case 'grid':
          return DataGrid;
        case 'single':
          return DataSingle;
        case 'tabs':
          return DataTabs;

        default:
          return undefined;
      }
    },

    bind() {
      const bind = { projectId: this.projectId, manifest: this.manifest };

      return Object.assign(bind, this.$attrs);
    },
  },
};
</script>

<style lang="sass">
@import odd-ds/dist/lib/index.sass
</style>
