export const buildDatasetOptions = (dataset, options, defaultOptions) => {
  const { metrics = [] } = options;
  let datasetOptions = {};

  // with in-place options...
  if (_.isPlainObject(dataset.options)) datasetOptions = dataset.options;
  // as an array...
  else if (_.isArray(metrics))
    datasetOptions = _.find(metrics, { id: dataset.id });
  // as an object...
  else if (_.isPlainObject(metrics))
    datasetOptions = { color: metrics[dataset.className] };

  return _.merge({}, defaultOptions, datasetOptions);
};

class DatasetsPreprocessor {
  constructor(options) {
    this.options = options;
  }

  map() {
    return _.identity(...arguments);
  }

  reduce() {
    return _.identity(...arguments);
  }

  preprocess() {
    return _.identity(...arguments);
  }
}

export default DatasetsPreprocessor;
