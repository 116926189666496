<template>
  <div :class="className">
    <icon v-if="icon" class="mr-3">{{ icon }}</icon>

    <div class="big-number-chart__body">
      <t
        v-bind="
          Object.assign(
            {},
            showValue ? { xs: true } : { sm: true, semibold: true }
          )
        "
      >
        {{ label_ }}
      </t>

      <div v-if="!fetching" class="d-flex-r d-flex-a-c h-spacing-2 mt-1">
        <t v-if="showValue" semibold lg no-wrap>
          {{ value | format(options) }}
        </t>

        <div v-if="showVariation" class="big-number-chart__variation">
          <icon class="mr-1">arrow-upward</icon>

          <t sm medium no-wrap>{{ variation | percentage }}</t>
        </div>
      </div>

      <Progress v-else color="secondary" :size="16" class="mt-1" />
    </div>
  </div>
</template>

<script>
import i18n from '@/i18n';
import { Chart } from '@/charting/mixins';
import { DEFAULT_DATASETS, DEFAULT_PREVIOUS_DATASETS } from './constants';
import { formatters, parseI18nText } from '@/helpers';
import { aggregate } from './helpers';

export default {
  name: 'BigNumberChart',
  filters: {
    format(value, { isWorkDay, isWorkDaySE }) {
      if (isWorkDay) return formatters('duration')(value);
      if (isWorkDaySE) return formatters('hourOfDay')(value);

      return formatters('abbreviate')(value, 9999);
    },
    percentage(value) {
      return value === Infinity
        ? i18n.tc('c.big-number-chart.infinity')
        : `${(value * 100).toFixed(1)}%`;
    },
  },
  mixins: [Chart],
  props: {
    icon: {
      type: String,
      default: undefined,
    },
    label: {
      type: [Object, String],
      default: undefined,
    },

    datasets: {
      type: [Array, Promise],
      default: DEFAULT_DATASETS,
    },
    previousDatasets: {
      type: [Array, Promise],
      default: DEFAULT_PREVIOUS_DATASETS,
    },
  },
  data() {
    return {
      // if <datasets> or <previousDatasets> are promises, <datasets_> and
      // <previousDatasets_> will store their values once solved

      datasets_: DEFAULT_DATASETS(),
      previousDatasets_: DEFAULT_PREVIOUS_DATASETS(),

      fetching: true,
    };
  },
  computed: {
    label_() {
      const { options = {} } = this;

      return parseI18nText(this.label) || options.aggregation;
    },
    value() {
      const { datasets_: datasets, options = {} } = this;
      if (!datasets || datasets.length === 0 || !options.aggregation)
        return NaN;

      return aggregate(datasets, options) || 0;
    },
    variation() {
      const { previousDatasets_: datasets, options = {} } = this;
      if (!datasets || datasets.length === 0 || !options.aggregation)
        return NaN;

      const previousValue = aggregate(datasets, options);

      return (this.value - previousValue) / previousValue || 0;
    },

    showValue() {
      return !_.isNaN(this.value);
    },
    showVariation() {
      return !_.isNaN(this.variation) && Math.abs(this.variation) <= 5;
    },

    className() {
      const className = 'big-number-chart';

      return [
        className,
        {
          [`${className}--negative`]:
            this.variation !== Infinity && this.variation < 0,
          [`${className}--positive`]:
            this.variation !== Infinity && this.variation > 0,
        },
      ];
    },
  },
  watch: {
    previousDatasets: { handler: 'fetch', immediate: true },
  },
  methods: {
    parseI18nText,

    async fetch() {
      try {
        this.fetching = true;

        this.datasets_ = await this.datasets;
        this.previousDatasets_ = await this.previousDatasets;
      } catch (error) {
        if (error.name !== 'CancellationError') throw error;
      } finally {
        this.fetching = false;
      }
    },
  },
};
</script>

<style lang="sass">
@import odd-ds/dist/lib/index.sass

// base
$icon-size: 16px

$negative-rotation: 180deg
$positive-rotation: 0deg

=variation-rotation($rotation)
  .big-number-chart__variation
    & > .e-icon, & > .e-text
      display: initial

    & > .e-icon
      transform: rotate($rotation)

.big-number-chart
  display: grid
  grid-template-areas: 'icon body' '. variation'
  grid-template-columns: auto 1fr
  grid-template-rows: auto auto
  align-items: center

// - icon
.big-number-chart > .e-icon
  grid-area: icon

  justify-self: center

// - body
.big-number-chart__body
  grid-area: body

  +d-flex-c

// - variation
.big-number-chart__variation
  grid-area: variation

  +d-flex-r(center)

  & > .e-icon, & > .e-text
    display: none

  & > .e-icon
    --size: #{$icon-size}

    border-radius: $b-radius-circle

.big-number-chart
  &--negative
    +variation-rotation($negative-rotation)

  &--positive
    +variation-rotation($positive-rotation)

// color
=variation-color($color)
  .big-number-chart__variation
    & > .e-icon, & > .e-text
      --color: #{$color}

.big-number-chart
  &--negative
    +variation-color($c-red)

  &--positive
    +variation-color($c-green)
</style>
