import legend from './legend';
import tooltips from './tooltips';
import scales from './scales';
import { LAYOUT } from './constants';
import { formatters } from '@/helpers';

// plugins
import annotation from './plugins/annotation';
import crosshair from './plugins/crosshair';

export default (tooltipsEl, options = {}) => {
  const {
    annotations = [],

    // axes
    axesType = 'time',
    granularity = 2,
    unit = undefined,
    yAxisPrecision = 0,
    isWorkDay = false,
    isWorkDaySE = false,
    stacked = false,

    // tooltips
    tooltipsPrecision = 2,
  } = options;

  const yFormatter = yLabel =>
    formatters(
      isWorkDay ? 'duration' : isWorkDaySE ? 'hourOfDay' : 'localedRound'
    )(yLabel, tooltipsPrecision);

  return {
    aspectRatio: 1.78,

    ...legend,
    ...tooltips(tooltipsEl, yFormatter),

    ...scales(axesType, {
      stacked,
      isWorkDay,
      isWorkDaySE,
      yAxisPrecision,
      unit,
      granularity,
    }),
    ...LAYOUT,

    ...annotation({ annotations }),

    plugins: { ...crosshair() },
  };
};
