<template>
  <div class="chart-js__empty">
    <Message
      icon="stats-bars"
      icon-color="gray-2"
      :message="$t('c.chart-js.is-empty')"
      message-color="gray-2"
    />
  </div>
</template>

<script>
// components
import Message from '@/components/utils/Message/index.vue';

export default {
  name: 'ChartJsEmpty',
  components: { Message },
};
</script>

<style lang="sass">
@import odd-ds/dist/lib/index.sass

// base
.chart-js__empty
  +d-flex-r(center, center)
  +p-absolute($z-index-base, 0)

  &::before
    content: ''

    pointer-events: none

    +p-absolute(#{$z-index-base - 1}, 0)

// color
.chart-js__empty::before
  background-color: var(--c-background)
</style>
